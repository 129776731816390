
import React, { useEffect, useState } from "react";
import SubQuestionComponent from "./SubQuestionComponent";
import { useFeedbackStore } from "./useFeedbackStore";

const MultiSelectComponent = ({ question }) => {
    const subQues = question.subQuestions || [];
    const setAnswers = useFeedbackStore(state => state.setAnswers);
    const selectedOptions = useFeedbackStore(state => state.selectedOptions);
    const [answerArray, setAnswerArray] = useState([]);
    const answers = useFeedbackStore(state => state.answers);
    const setArray = (option) => {
        const newArray = [...answerArray];
        const optionIndex = newArray.indexOf(option);
        if (optionIndex > -1) {
            newArray.splice(optionIndex, 1);
        } else {
            newArray.push(option);
        }
        setAnswerArray(newArray);
        setAnswers(question.id, newArray, question);
    };
    useEffect(() => {
        if (selectedOptions.length == 0) {
            setAnswerArray([]);
        }
    }, [selectedOptions]);
    if (!question.extras || question.extras.length==0) {
        return null;
      }
    return (
        <div >
            <div className="main-star">
                <span className="font-question" style={{ marginBottom: "10px" }}>{question.text}
                    {question.required === true ?
                        <sup style={{ color: 'red', fontSize: "14px", marginLeft: '3px' }}>*</sup> : null}
                </span>
                {question.extras.options.map((i, index) => {
                    const isChecked = answerArray.includes(i);
                    const [filled, setFilled] = useState(false);
                    useEffect(() => {
                        if (answerArray.length == 0) {
                            if (isChecked == false && answerArray.length == 0 && filled == false) {
                                setFilled(true);
                            } else if (isChecked == false && answerArray.length == 0 && filled == true) {
                                if (answers.find(p => p.id == question.id)) {
                                    answers.pop(question.id);
                                }
                            }
                        }
                    }, [isChecked]);
                    return (
                        <div className="font-selectarea">
                            <label style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                                <input type="checkbox" className="input-checkbox"
                                    checked={isChecked}
                                    onChange={() => {
                                        setArray(i, question);
                                    }}
                                />
                                {i}
                            </label>
                        </div>
                    )
                })}
            </div>
            <div>
                {subQues.map((i, index) => {
                    return <SubQuestionComponent question={i} key={index} />
                })}
            </div>
        </div>
    )
}

export default MultiSelectComponent;
