import React, { useState } from "react";

const TextInputComponent = ({ item, validate }) => {

        const [error, setError] = useState(false);
        const [nameValid, setNameValid] = useState(true);
        const [emailValid, setEmailValid] = useState(true);

        const validateInput = (text) => {
                if (item.slug === 'name') {
                        const isNameValid = /^[A-Za-z ]+$/.test(text);
                        setError(false);
                        setNameValid(isNameValid);
                } else if (item.slug === 'email') {
                        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(text);
                        setError(false);
                        setEmailValid(isValidEmail);
                } else {
                        setNameValid(false);
                        setEmailValid(false);
                }

                validate(text);
        };

        return (
                <div style={{ width: '100%' }}>
                        <div>
                                <label className="font-form-question ldap-center" style={{margin:10}}>
                                        <span style={{marginBottom:7}}> {item.label}
                                                {item.required === '1' ?
                                                        <sup style={{ color: 'red', fontSize: "14px", marginLeft: '3px' }}>*</sup> : null}
                                        </span>
                                        <input type="text" className="input-form input-align"
                                                placeholder={item.placeHolder}
                                                // onChange={(e) => validate(e.target.value)}
                                                onBlur={() => {
                                                        if (item.slug === 'name' && !nameValid) {
                                                                setError(true);
                                                        } else if (item.slug === 'email' && !emailValid) {
                                                                setError(true);
                                                        } else {
                                                                setError(false);
                                                        }
                                                }}
                                                onChange={(e) => {
                                                        validateInput(e.target.value);
                                                }}
                                        >

                                        </input>
                                </label>
                        </div>
                        {error ? <span style={{ color: 'red', fontSize: '13px', margin: '10px',paddingLeft:10 }}>{item.massage}</span> : null}

                </div>
        )
}
export default TextInputComponent;
