
import React from "react";
import { useFeedbackStore } from "./useFeedbackStore";
import StarRatingComponent from "./StarRatingComponent";
import EmojiRatingComponent from "./EmojiRatingComponent ";
import NPSComponent from "./NPSComponent";
import TextAreaComponent from "./TextAreaComponent";
import SelectComponent from "./SelectComponent";
import MultiSelectComponent from "./MultiSelectComponent";

const FormComponent = () => {
        const questionList = useFeedbackStore(i => i.questionList);
        return (
                <div>
                        {questionList && questionList.map((i, index) => {
                                const questions = {
                                        "rating": 
                                                <StarRatingComponent
                                                        item={i}
                                                        key={index}
                                                        question={i}
                                                />,
                                        "emoticon":
                                                <EmojiRatingComponent
                                                        item={i}
                                                        key={index}
                                                        question={i}
                                                />,
                                        "nps":
                                                <NPSComponent
                                                        item={i}
                                                        key={index}
                                                        question={i}
                                                />,
                                        "text":
                                                <TextAreaComponent
                                                        item={i}
                                                        key={index}
                                                        question={i}
                                                />
                                        ,
                                        "select":
                                                <SelectComponent
                                                        item={i}
                                                        key={index}
                                                        question={i}
                                                />
                                        ,
                                        "multi-select":
                                                <MultiSelectComponent
                                                        item={i}
                                                        key={index}
                                                        question={i}
                                                />

                                }
                                return questions[i.widget_type] || null;
                        })}
                </div>
        )
}

export default FormComponent;