
import { useFeedbackStore } from "./useFeedbackStore";
import React from 'react';
import FormComponent from './FormComponent';
import MessageComponent from './MessageComponent';
import ButtonSubmitComponent from './ButtonSubmitComponent';
import UserFormComponent from './UserFormComponent';
import FeedbackSuccessComponent from "./FeedbackSuccessComponent";
import DetailedFeedbackLinkComponent from "./DetailedFeedbackLinkComponent";

const FeedbackForm = ({ index }) => {
  const questionList = useFeedbackStore(i => i.questionList);
  const saveFeedbackSuccess = useFeedbackStore(i => i.saveFeedbackSuccess);
  const config = useFeedbackStore(i => i.config);
  if (!questionList || questionList.length === 0) {
    return <MessageComponent />;
  }
  if (saveFeedbackSuccess === true) {
    return <FeedbackSuccessComponent />;
}
  return (
    <div>
      {config.userDetailsPositioning === 'top' && <UserFormComponent />}
      <FormComponent />
      {config.userDetailsPositioning === 'bottom' && <UserFormComponent />}
      <ButtonSubmitComponent />
      {config?.alternativeFeedbackId && <DetailedFeedbackLinkComponent /> }
    </div>
  )
}

export default FeedbackForm;