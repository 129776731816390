import React from "react";
import { useFeedbackStore } from "./useFeedbackStore";
import { FaChevronCircleRight } from "react-icons/fa";

const ButtonSubmitComponent = () => {
    const checkRequired = useFeedbackStore(i => i.checkRequired);
    const isLoading = useFeedbackStore(i => i.isLoading);
    return (
        <div style={{ display: "flex", alignItems: "center", marginTop: 10, justifyContent: "center", width: '100%' }}>
            <button type="text" className="button" style={{ backgroundColor: "#000000", color: "#FFFFFF" }}
                onClick={() => {
                    checkRequired();
                }}
            >
                {isLoading ?
                    <p style={{textAlign:'center'}}>Loading...</p>
                 :
                    <p style={{textAlign:'center',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',width:'100%'}}> Submit <FaChevronCircleRight /></p>}
            </button>
        </div>
    )
}
export default ButtonSubmitComponent;