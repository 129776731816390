import { useEffect } from 'react';
import './App.css';
import './components/FeedbackForm.css';
import MainComponent from './components/MainComponent';
import { useFeedbackStore } from './components/useFeedbackStore';

const App = () => {
  const config = useFeedbackStore(i => i.config);
  const rootElement = document.getElementById('root');
  rootElement.style.backgroundColor = config.bgColor || '#fff';

  return <MainComponent />
}

export default App;