
import React from "react";
import { useFeedbackStore } from "./useFeedbackStore";

const BackgroundImageComponent = () => {
        const config = useFeedbackStore(i => i.config);
        return (
                <div>
                        {config?.topRightImage && <img src={config?.topRightImage} style={{ width: 50, height: 50, resizeMode: "contain", position: 'absolute',right:0,top:0 }} />}
                        {config?.topLeftImage && <img src={config?.topLeftImage} style={{ width: 50, height: 50, resizeMode: "contain", position: 'absolute',left:0,top:0 }} />}
                        {config?.bottomLeftImage && <img src={config?.bottomLeftImage} style={{ width: 120, height: 70, resizeMode: "contain", position: "absolute", backgroundColor: 'transparent',bottom:'-16px',left:'-10px' }} />}
                        {config?.bottomRightImage && <img src={config?.bottomRightImage} style={{ width: 74, height: 21, resizeMode: "contain", position: "absolute", backgroundColor: 'transparent',bottom:9,right:10 }} />}
                </div>
        )
}

export default BackgroundImageComponent;