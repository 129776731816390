
import React from "react";
import { useFeedbackStore } from "./useFeedbackStore";

const MessageComponent = () => {
        const config = useFeedbackStore(i => i.config);
        const msg = useFeedbackStore(i => i.msg);
        return (
                <div style={{ color: config.messageTextColor || '#ddd',display:'flex',justifyContent:'center' }} className='invalid-img'>
                        <span className="submit-text">{msg}</span>
                </div>
        )
}

export default MessageComponent;