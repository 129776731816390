
import React from "react";
import { useFeedbackStore } from "./useFeedbackStore";

const DateInputComponent = ({ item, validate }) => {

        return (
                <div style={{ width: '100%' }}>
                        <div>
                                <label className="font-form-question" style={{margin:10}} >
                                        <span style={{marginBottom:7}}>
                                                {item.label}
                                                {item.required === '1' ?
                                                        <sup style={{ color: 'red', fontSize: "14px", marginLeft: '3px' }}>*</sup> : null}
                                        </span>
                                        <input type="date" className="input-form"
                                                placeholder={item.placeHolder}
                                                onChange={(e) => validate(e.target.value)} >
                                        </input>
                                </label>
                        </div>
                </div>
        )
}
export default DateInputComponent;
