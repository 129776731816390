import { create } from "zustand";
import { makeGetApiCall, makePostApiCall, URLS } from "./apiClient";

export const useFeedbackStore = create((set, get) => ({
  selectedQuestions: [],
  selectedRating: null,
  config: {},
  theme: {},
  userFormConfig: [],
  questionList: [],
  requiredQuestionList: [],
  questionIdsList: [],
  msg: null,
  answers: [],
  answersList: [],
  saveFeedbackResponse: null,
  selectedOptions: [],
  options: ["select", "male", "female"],
  selected: null,
  isSubmitted: false,
  itemsList: [],
  validationMessages: {},
  userDetails: false,
  userData: {},
  saveFeedbackSuccess: false,
  validateIdentifier: [],
  open:false,
  isLoading: null, 
  setOpen: (open) => set({ open }),
  setResetAnswer: (answers) => set({ answers:answers }),
  setValidateIdentifier: (validateIdentifier) => set({ validateIdentifier: validateIdentifier }),
  setUserData: (slug, value) => {
    const currentFormData = get().userData;
    set({ userData: { ...currentFormData, [slug]: value } });
  },
  setValidationMessages: (validationMessages) => set({ validationMessages }),
  setAnswers: (id, answer) => {
    const answers = get().answers;
    var temp = answers.find((i, index) => {
      return i.id === id;
    })
    if (!temp) {
      set((state) => ({ answers: [...state.answers, { id: id, value: answer }] }))
    } else {
      var arrayAnswers = answers.filter((i, index) => {
        return id !== i.id;
      })
      set(() => ({ answers: [...arrayAnswers, { id: id, value: answer }] }))
    }
  },
  setSelected: (option) => set(() => ({ selected: option })),
  getFeedback: async (invoiceId, authToken, merchantAccessToken, merchantId, feedbackId, sourceId) => {
    const response = await makeGetApiCall(URLS.getFeedback, { id: invoiceId, authToken, merchantAccessToken, merchantId, feedbackId, sourceId });
    if (response.success) {
      set({
        questionList: response.questions,
        itemsList: response.items,
        userAvailable: response.userAvailable,
        profileAvailable: response.profileAvailable,
      })
    }
    else {
      set({
        questionList: [],
        msg: response.message,
      })
    }
    var questionArray = [];
    response.questions && response.questions.map((i, index) => {

      if (i.required === true) {
        questionArray.push(i.id);
      }
    })
    set(() => ({ questionIdsList: questionArray }));
    set(() => ({ requiredQuestionList: questionArray }));
  },
  saveFeedback: async (invoiceId, authToken, merchantAccessToken, merchantId, feedbackId, sourceId) => {
    set({ isLoading: true});
    try {
      const response = await makePostApiCall(URLS.saveFeedback, {
        id: invoiceId,
        authToken,
        merchantAccessToken,
        merchantId,
        feedbackId,
        sourceId,
        data: get().answers,
        user: get().userData
      });
      
      if (response.success) {
        set({
          saveFeedbackSuccess: response.success,
          saveFeedbackResponse: response,
        });
      } else {
        set({
          msg: response.message,
          saveFeedbackSuccess: false
        });
      }
    } catch (error) {
      set({
        msg: error.message || 'An unexpected error occurred',
        saveFeedbackSuccess: false
      });
    } finally {
      set({ isLoading: false});
    }
  },
  checkRequired: async () => {
    const requiredQuestionList = get().requiredQuestionList;
    const answers = get().answers;
    var answersList = [];
    get().onSubmit();
    answers.map((i, index) => {
      answersList.push(i.id);
    })
    let result = requiredQuestionList.filter(o1 => answersList.some(o2 => o1 === o2));
    if (result.length == requiredQuestionList.length) {
      set({
        isSubmitted: true,
      })
    }
    else {
      alert('please check the required fields')
    }
  },

  getConfig: async (merchantAccessToken) => {
    const response = await makeGetApiCall(URLS.content, { model: 'configuration', merchantAccessToken, template: 'feedback-microsite-1' });
    if (response.success) {
      set({
        config: response.data,
      })
    }
  },
  getTheme: async (merchantAccessToken) => {
    const response = await makeGetApiCall(URLS.content, { model: 'theme', template: 'feedback-microsite-1', merchantAccessToken });
    if (response.success) {
      set({
        theme: response.data,
      })
    }
  },
  getUserFormConfig: async () => {
    const response = await makeGetApiCall(URLS.content, { model: 'userFormConfiguration', template: 'feedback-microsite-1' });
    if (response.success) {
      set({
        userFormConfig: response.data,
      })
    }
  },

  setRequiredQuestions: (item, value, selected) => {
    var questionIds = [];
    const tempList = get().questionIdsList;
    if (item.required === true && item.subQuestions.length > 0) {
      item.subQuestions.map((j, index) => {
        if (j.threshold?.from <= value && value <= j.threshold?.to) {
          if (j.required == true && !tempList.includes(j.id)) {
            if (selected) {
              questionIds.push(j.id);
            } else {
              questionIds.pop(j.id);
            }
          }
        } else {
          tempList.map((l) => {
            if (item.subQuestions.find(p => p.id == l)) {
              if (l != j.id) {
                tempList.pop(l);
              }
            }
          });
        }
      });
    }
    set(() => ({
      requiredQuestionList: [...tempList, ...questionIds],
    }));
  },
  onChange: (value, slug) => {
    const currentFormData = get().userData;
    const currentValidationMessages = get().validationMessages;
    set({
      userData: { ...currentFormData, [slug]: value },
      validationMessages: { ...currentValidationMessages, [slug]: "" }
    });
  },
  onSubmit: async () => {
    const { userFormConfig, userData, validateIdentifier } = get();
    const newValidationMessages = {};
    const user = Object.keys(userData).length;
    const requiredSlugs = validateIdentifier.filter(field => field.required === "1").map(field => field.slug);
    if (userFormConfig.length > 0) {
      userFormConfig.forEach((field) => {
        const { slug, required } = field;
        if (required === "1" && !userData[slug]) {
          newValidationMessages[slug] = `This field is required`;
        }
      });
      if (Object.keys(newValidationMessages).length > 0) {
        set({
          validationMessages: newValidationMessages,
        });
      }
      if (user >= requiredSlugs.length) {
        set({
          userDetails: true,
        })
      }
    }
  }
}))