
let BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL + '/dedicated-api' : 'https://www.froogal.in/dedicated-api';
let MERCHANT_ACCESS_TOKEN = process.env.REACT_APP_MERCHANT_ACCESS_TOKEN;
let params = new URL(document.location).searchParams;

let AUTH_TOKEN = params.get("token");

export const setBaseUrl = (baseUrl) => {
    BASE_URL = baseUrl;
};

export const setMerchantAccessToken = (token) => {
    MERCHANT_ACCESS_TOKEN = token;
};

export const setToken = (token) => {
    AUTH_TOKEN = token;
};

export const URLS = {
    getFeedback: 'merchant/get-feedback',
    saveFeedback: 'merchant/save-feedback-response',
    content: 'content'
};

const defaultHeaders = {
    'Accept': 'application/json',
};


const makeApiCall = async (uri, method = 'GET', params = {}, headers = {}) => {
   
    const urlParams = method === 'GET' ? new URLSearchParams(params).toString() : '';
    const urlSuffix = (urlParams ? '?' : '') + urlParams;
    const bodyParams = method === 'POST' ? JSON.stringify(params) : null;

    const headersToSend = {
        ...defaultHeaders,
        ...(AUTH_TOKEN ? { 'Authorization': `Bearer ${AUTH_TOKEN}` } : {}),
        ...(method === 'POST' ? { 'Content-Type': 'application/json' } : {}),
        ...headers,
        ...(MERCHANT_ACCESS_TOKEN ? { 'X-Merchant-Access-Token': MERCHANT_ACCESS_TOKEN } : {}),
        'Accept': 'application/json',
    };
    const urlToCall = uri.startsWith('http') ? uri : `${BASE_URL}/${uri}${urlSuffix}`;
    const response = await fetch(
        urlToCall,
        {
            method,
            body: bodyParams,
            headers: headersToSend,
        }

    );
    return await response.json();
};

export const makeGetApiCall = async (url, params = {}, headers = {}) => {
    return makeApiCall(url, 'GET', params, headers);
};

export const makePostApiCall = async (url, params = {}, headers = {}) => {
    return makeApiCall(url, 'POST', params, headers);
};



