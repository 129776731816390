
import React, { useEffect, useState } from "react";
import { useFeedbackStore } from "./useFeedbackStore";
import SubQuestionComponent from "./SubQuestionComponent";

const EmojiRatingComponent = ({ question, index }) => {
    const setAnswers = useFeedbackStore(i => i.setAnswers);
    const [selected, setSelected] = useState(-1);
    const subQues = question.subQuestions || [];
    const answers = useFeedbackStore(i => i.answers);
    const options = [{
        name: 'Bad', image: require('./images/sad_emoji.png'), rate: 1
    }, {
        name: 'Average', image: require('./images/meh_emoji.png'), rate: 2
    }, {
        name: 'Good', image: require('./images/good_emoji.png'), rate: 3
    },
    {
        name: 'Awesome', image: require('./images/awesome_emoji.png'), rate: 4
    },
    {
        name: 'Loved it', image: require('./images/lovedit_emoji.png'), rate: 5
    },
    ];
    return (
        <div className="main-star" style={{ marginBottom: "10px" }}>
            <span className="font-question" style={{ marginBottom: "10px"}}>{question.text}
                {question.required === true ?
                    <sup style={{ color: 'red', fontSize: "14px", marginLeft: '3px' }}>*</sup> : null}
            </span>
            <div style={{ display: "flex", flexDirection: "row", paddingRight: "10px", justifyContent: "space-around", }}>
                {options && options.map((i, index) => {
                    return <RenderItem
                        item={i}
                        key={index}
                        index={index}
                        image={i.image}
                        name={i.name}
                        selected={selected}
                        setSelected={setSelected}
                        answers={answers}
                        question={question}
                        answer={answers.find(i => i.id == question.id)}
                        setAnswer={(answer) => {
                            setAnswers(question.id, answer, question);
                        }} />
                })}
            </div>
            {subQues.map((i, index) => {
                return <SubQuestionComponent question={i} key={index} />
            })}

        </div>
    )
}
const RenderItem = ({ item, index, selected, setSelected, setAnswer, answer, answers, question }) => {
    const [imageStatus, setImage] = useState(false);
    const theme = useFeedbackStore(i => i.theme);
    const [filled, setFilled] = useState(false);
    const value = answer?.value;
    useEffect(() => {
        setImage(index === selected ? true : false)
    }, [selected]);
    useEffect(() => {
        if (answer?.value) {
            setImage(index < answer?.value ? true : false);
        }
    }, []);
    useEffect(() => {
        if (value == index + 1) {
            if (imageStatus == false && answer?.value == index + 1 && filled == false) {
                setFilled(true);
                if (answers.find(p => p.id == question.id)) {
                    answers.pop(question.id);
                }
            } 
        }
    }, [selected, imageStatus, value]);
    useEffect(()=>{
        if (answers.length == 0) {
            setImage(false);
        }
    },[answer]);
    return (
        <button className="emoji-button" onClick={() => {
            setImage(!imageStatus);
            setSelected(index);
            setAnswer(item.rate);
        }} >
            <label className="font-exp" style={{ alignItems: 'center' }}>
            <img src={item.image} className="img-responsive" />
                <span
                    className={!imageStatus ? "font-inactive" : "font-active"}
                    style={{
                        color: !imageStatus ? theme?.textColor || "#788494" : theme?.secondaryColor || "#c10031",
                        fontWeight: !imageStatus ? null : "bold",
                    }} >
                    {item.name}
                </span>
            </label>
        </button>
    )
}

export default EmojiRatingComponent;