import React, { useState } from "react";
import { useFeedbackStore } from "./useFeedbackStore";

const DetailedFeedbackLinkComponent = () => {
    const theme = useFeedbackStore(i => i.theme);
    const config = useFeedbackStore(i => i.config);
    const getFeedback = useFeedbackStore(i => i.getFeedback);
    const setOpen = useFeedbackStore(i => i.setOpen);
    const open = useFeedbackStore(i => i.open);
    const setResetAnswer = useFeedbackStore(i => i.setResetAnswer);
    const url = new URL(document.location);
    const params = url.searchParams;
    let invoiceId = params.get("invoiceId");
    let sourceId = params.get("sourceId");
    let authToken = params.get("authToken");
    let merchantAccessToken = params.get("merchantAccessToken");
    let merchantId = params.get("merchantId");

    return (
        <div style={{ display: 'flex', justifyContent: 'center', margin: 10, marginRight: 10,marginBottom:60 }}>
            {!open && 
            <button className="detailed-button" style={{ borderBottomColor: theme.primaryColor }}
                onClick={() => {
                    getFeedback(invoiceId, authToken, merchantAccessToken, merchantId, config?.alternativeFeedbackId, sourceId);
                    setOpen(!open);
                    setResetAnswer([]);
                }}
            >{config?.alternativeFeedbackCTA || 'Give Feedback'}</button>}
        </div>
    )
}
export default DetailedFeedbackLinkComponent;