
import React, { useState } from "react";
import SubQuestionComponent from "./SubQuestionComponent";
import { useFeedbackStore } from "./useFeedbackStore";

const SelectComponent = ({ question }) => {
    const subQues = question.subQuestions || [];
    const setAnswers = useFeedbackStore(state => state.setAnswers);
    const [active, setActive] = useState(false);
    if (!question.extras || question.extras.length==0) {
        return null;
      }
    return (
        <div >
            <div className="main-star">
                <span className="font-question" style={{ marginBottom: "10px"}}>{question.text}
                    {question.required === true ?
                        <sup style={{ color: 'red', fontSize: "14px", marginLeft: '3px' }}>*</sup> : null}
                </span>
                {question.extras.options.map((i, index) => {
                    return (
                        <button style={{
                            backgroundColor: "#fff",
                            padding: 8,
                            backgroundColor: "#fff",
                            padding: 15,
                            margin: '10px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: 'none',
                            borderRadius: 4,
                            boxShadow: active === i ? "inset 0px 0px 5px #000" : null,
                            fontSize: '15px'
                        }}
                            onClick={() => {
                                setAnswers(question.id, i, question);
                                setActive(i);
                            }}
                        >{i}</button>
                    )
                })}
            </div>
            <div>
                {subQues.map((i, index) => {
                    return <SubQuestionComponent question={i} key={index} />
                })}
            </div>
        </div>
    )
}

export default SelectComponent;