
import React, { useEffect, useState } from "react";
import { useFeedbackStore } from "./useFeedbackStore";
import SubQuestionComponent from "./SubQuestionComponent";
const StarRatingComponent = ({ question }) => {
    const [selected, setSelected] = useState(null);
    const setAnswers = useFeedbackStore(i => i.setAnswers);
    const answers = useFeedbackStore(i => i.answers);
    const options = [{
        name: 'Bad', rate: 1
    }, {
        name: 'Average', rate: 2
    }, {
        name: 'Good', rate: 3
    },
    {
        name: 'Awesome', rate: 4
    },
    {
        name: 'Loved it', rate: 5
    },
    ];
    const subQues = question.subQuestions || [];
    useEffect(() => {
        if (answers.length == 0) {
            setSelected(null);
        }
    }, [answers]);
    const handleSelection = (rate) => {
        setSelected((prevSelected) => {
            if (prevSelected === rate) {
                if (answers.find(p => p.id == question.id)) {
                    answers.pop(question.id);
                } else {
                    setAnswers(null);
                }
                return null;
            } else {
                setAnswers(question.id, rate, question);
                return rate;
            }
        });
    };
    return (
        <div style={{ width: "100%" }}>
            <div className="main-star">
                <span className="font-question" style={{ marginBottom: "10px" }}>
                    {question.text}
                    {question.required && <sup style={{ color: 'red', fontSize: "14px", marginLeft: '3px' }}>*</sup>}
                </span>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-around', paddingRight: "10px" }}>
                    {options.map((item, index) => (
                        <RenderItem
                            item={item}
                            index={index}
                            key={index}
                            selected={selected}
                            handleSelection={handleSelection}
                        />
                    ))}
                </div>
            </div>
            {subQues.map((i, index) => (
                <SubQuestionComponent question={i} key={index} />
            ))}
        </div>
    );
};

const RenderItem = ({ item, index, selected, handleSelection, setAnswer }) => {
    const theme = useFeedbackStore(i => i.theme);
    const isSelected = item.rate <= selected;

    return (
        <div>
            <label className="font-exp">
                <button
                    key={index}
                    style={{ border: "none", backgroundColor: "#F7F9FC" }}
                    onClick={() => {
                        handleSelection(item.rate);
                    }}
                >
                    <img
                        className="star-img"
                        src={isSelected ? theme?.filledStarIcon || require('./images/red_star_2.png') : theme?.emptyStarIcon || require('./images/grey-star.png')}
                    />
                </button>
                <span
                    className="font-inactive"
                    style={{ color: isSelected ? theme?.secondaryColor || '#C10031' : theme?.textColor || 'rgb(120, 132, 148)' }}>
                    {item.name}
                </span>
            </label>
        </div>
    );
};

export default StarRatingComponent;