
import React, { useState } from "react";
import { useFeedbackStore } from "./useFeedbackStore";
import SubQuestionComponent from "./SubQuestionComponent";

const NPSComponent = ({ question }) => {
    const setAnswers = useFeedbackStore(i => i.setAnswers);
    const subQues = question.subQuestions || [];
    const [selected, setSelected] = useState(-1);
    const setRequiredQuestions = useFeedbackStore(i => i.setRequiredQuestions);
    const answers = useFeedbackStore(i => i.answers);
    const removeRequiredList = useFeedbackStore(i => i.removeRequiredList)
    const buttonsList = [{
        title: "1", rate: 0.5, color: "#e18264"

    }, {
        title: "2", rate: 1, color: "#e18264"

    }, {
        title: "3", rate: 1.5, color: "#e18264"

    }, {
        title: "4", rate: 2, color: "#e18264"

    }, {
        title: "5", rate: 2.5, color: "#e18264"

    }, {
        title: "6", rate: 3, color: "#e18264"

    }, {
        title: "7", rate: 3.5, color: "#f9da7e"

    }, {
        title: "8", rate: 4, color: "#f9da7e"

    }, {
        title: "9", rate: 4.5, color: "#489354"

    }, {
        title: "10", rate: 5, color: "#489354"

    }];
    return (
        <div style={{ width: "100%" }}>
            <div className="main-star">
                <span className="font-question">{question.text}
                    {question.required === true ?
                        <sup style={{ color: 'red', fontSize: "14px", marginLeft: '3px' }}>*</sup> : null}
                </span>
                <div className="nps-button-div">
                    {buttonsList && buttonsList.map((i, index) => {
                        return (
                            <RenderItem
                                item={i}
                                index={index}
                                key={index}
                                selected={selected}
                                question={question}
                                setSelected={setSelected}
                                setRequiredQuestions={setRequiredQuestions}
                                answer={answers.find(i => i.id == question.id)}
                                removeRequiredList={removeRequiredList}
                                setAnswer={(answer) => {
                                    setAnswers(question.id, answer, question);
                                }} />
                        )
                    })}
                </div>
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", marginTop: "10px", width: '100%' }}>
                    <div className="img-nps" style={{ justifyContent: 'flex-start' }}>
                        <img src={require('./images/sad_emoji.png')} style={{ width: "10%" }} />
                        <span >1-NOT LIKELY</span>
                    </div>
                    <div className="img-nps" style={{ justifyContent: 'flex-end' }}>
                        <img src={require('./images/good_emoji.png')} style={{ width: "10%" }} />
                        <span >10-VERY LIKELY</span>
                    </div>
                </div>
            </div>
                <div>
                    {subQues.map((i, index) => {
                            return <SubQuestionComponent question={i} key={index} selected={selected} />
                    })}
                </div> 
        </div>
    )
}
const RenderItem = ({ item, index, selected, setSelected, setAnswer, setRequiredQuestions, question }) => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "2px", paddingLeft: "2px", flex: "10%" }}>
            <div className="nps-buttons" style={{ position: "relative" }}>
                <button style={{
                    backgroundColor: item.color,
                    boxShadow: item.rate == selected ? "inset 0px 0px 5px #000" : null,
                    border: item.rate == selected ? "2px solid #000" : null,
                    fontWeight: item.rate == selected ? "bold" : null,

                }} key={index}
                    onClick={() => {
                        setSelected(item.rate);
                        setAnswer(item.rate);
                        setRequiredQuestions(question, item.rate, selected);
                    }} className="button-nps ">{item.title}</button>
            </div>
        </div>
    )
}

export default NPSComponent;