
import React, { useEffect, useState } from "react";
import { useFeedbackStore } from "./useFeedbackStore";
import { Liquid } from 'liquidjs';

const FeedbackSuccessComponent = () => {
  const saveFeedbackResponse = useFeedbackStore(i => i.saveFeedbackResponse);
  const [renderedMessage, setRedenderedMessage] = useState('');
  const config = useFeedbackStore(i => i.config);
  useEffect(() => {
    if (saveFeedbackResponse) {
      const engine = new Liquid();
      const tpl = engine.parse((config.thankYouMessage) || '');
      const renderedTemplate = engine.render(tpl, {
        offer: saveFeedbackResponse.offer,
        couponCode: saveFeedbackResponse.couponCode,
      });
      renderedTemplate.then((message) => {
        setRedenderedMessage(message);
      });
    }
  }, [saveFeedbackResponse]);
  return (
    <div style={{ maxWidth: "100%", display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column", margin: "0px 20px" }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10, marginTop: 20, marginBottom: 20 }}>
      <img className='savefeedback-img' src={config?.thankYouImage} style={{marginBottom:13}}/>
        {config?.thankYouMessage ?
          <div dangerouslySetInnerHTML={{ __html: renderedMessage }} style={{textAlign:'center'}}/>
          :
          <span style={{ color: "green", textAlign: "center", fontSize: "25px" }}>Thanks for taking time out to give your feedback.</span>
        }
      </div>
    </div>  
  )
}

export default FeedbackSuccessComponent;