
import React from "react";
import SubQuestionComponent from "./SubQuestionComponent";
import { useFeedbackStore } from "./useFeedbackStore";

const TextAreaComponent = ({ question }) => {
    const subQues = question.subQuestions || [];
    const setAnswers = useFeedbackStore(state => state.setAnswers);
    const answers = useFeedbackStore(i => i.answers);
    const handleChange = (e) => {
        { answers.find(i => i.id == question.id) }
        if (e.target.value == "") {
            answers.pop(question.id);
        } else {
            setAnswers(question.id, (e.target.value), question);
        }
    }
    return (
        <div>
            <div className="textarea-main">
                <span className="font-question">{question.text}
                    {question.required === true ?
                        <sup style={{ color: 'red', fontSize: "14px", marginLeft: '3px' }}>*</sup> : null}
                </span>
                <textarea className="input-textarea" onChange={handleChange}></textarea>
            </div>
            {subQues.map((i, index) => {
                return <SubQuestionComponent question={i} key={index}/>

            })}

        </div>
    )
}

export default TextAreaComponent;