
import React, { useEffect } from "react";
import { useFeedbackStore } from "./useFeedbackStore";
import FeedbackForm from "./FeedbackForm";
import LogoComponent from "./LogoComponent";
import TitleComponent from "./TitleComponent";
import BackgroundImageComponent from "./BackgroundImageComponent";
import { setMerchantAccessToken, setToken } from "./apiClient";
import { FaChevronCircleLeft } from "react-icons/fa";

const MainComponent = () => {
        const config = useFeedbackStore(i => i.config);
        const getConfig = useFeedbackStore(i => i.getConfig);
        const getTheme = useFeedbackStore(i => i.getTheme);
        const getFeedback = useFeedbackStore(i => i.getFeedback);
        const saveFeedback = useFeedbackStore(i => i.saveFeedback);
        const getUserFormConfig = useFeedbackStore(i => i.getUserFormConfig);
        const isSubmitted = useFeedbackStore(i => i.isSubmitted);
        const userDetails = useFeedbackStore(i => i.userDetails);
        const userFormConfig = useFeedbackStore(i => i.userFormConfig);
        const open = useFeedbackStore(i => i.open);
        const setOpen = useFeedbackStore(i => i.setOpen);
        const setResetAnswer = useFeedbackStore(i => i.setResetAnswer);
        const url = new URL(document.location);
        const params = url.searchParams;
        let invoiceId = params.get("invoiceId");
        let feedbackId = params.get("feedbackId");
        let sourceId = params.get("sourceId");
        let authToken = params.get("authToken");
        let merchantAccessToken = params.get("merchantAccessToken");
        let merchantId = params.get("merchantId");
        const feedbackinitialId = feedbackId && feedbackId || config?.feedbackId;
        useEffect(() => {
                if (feedbackId != null || undefined || config?.feedbackId) {
                        getFeedback(invoiceId, authToken, merchantAccessToken, merchantId, feedbackinitialId, sourceId);
                }
        }, [config?.feedbackId || feedbackId]);
        useEffect(() => {
                getFeedback(invoiceId, authToken, merchantAccessToken, merchantId, feedbackId, sourceId);
                getTheme(merchantAccessToken);
                getConfig(merchantAccessToken);
                setToken(authToken);
                setMerchantAccessToken(merchantAccessToken);
                getUserFormConfig();
        }, []);
        useEffect(() => {
                document.title = config.pageTitle;
                var link = document.querySelector("link[rel~='icon']");
                if (!link) {
                        link = document.createElement('link');
                        link.rel = 'icon';
                        document.head.appendChild(link);
                }
                link.href = config?.favicon;

        }, [config]);
        useEffect(() => {
                if (isSubmitted == true && userDetails == true) {
                        saveFeedback(invoiceId, authToken, merchantAccessToken, merchantId, feedbackId, sourceId);
                } else if (isSubmitted == true && userFormConfig.length == 0) {
                        saveFeedback(invoiceId, authToken, merchantAccessToken, merchantId, feedbackId, sourceId);
                }
        }, [isSubmitted, userDetails, userFormConfig]);
        return (
                <div className='main-app'>
                        <style>{config?.customCss}</style>
                        <div className="main-form" style={{ maxWidth: '100%' }}>
                                {open === true &&
                                        <div style={{ display: 'flex', alignItems: 'center', margin: 20 }}>
                                                <button style={{ borderRadius: 100, width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none', backgroundColor: '#fff' }}
                                                        onClick={() => {
                                                                setOpen(false);
                                                                getFeedback(invoiceId, authToken, merchantAccessToken, merchantId, feedbackinitialId, sourceId);
                                                                setResetAnswer([]);
                                                        }}
                                                >
                                                        <FaChevronCircleLeft size={30} style={{ textAlign: 'center' }} />
                                                </button>
                                        </div>}
                                <BackgroundImageComponent />
                                <LogoComponent />
                                <TitleComponent />
                                <FeedbackForm />
                        </div>
                </div>
        )
}

export default MainComponent;