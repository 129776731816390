
import React from "react";
import { useFeedbackStore } from "./useFeedbackStore";

const LogoComponent = () => {
        const config = useFeedbackStore(i => i.config);
        return (
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <img src={config?.logo} className='img-cafe logo'  style={{height:60}}/>
                </div>
        )
}

export default LogoComponent;