
import React from "react";
import { useFeedbackStore } from "./useFeedbackStore";

const TitleComponent = () => {
        const config = useFeedbackStore(i => i.config);
        return (
                <div>
                        {config?.title && <h1 className='feedback' >{config?.title}</h1>}
                        {config?.description && <p className='feedback-font'>{config?.description}</p>}
                </div>
        )
}

export default TitleComponent;