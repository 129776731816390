
import React from "react";
import { useFeedbackStore } from "./useFeedbackStore";

const DropdownInputComponent = ({ item, validate }) => {

    const options = useFeedbackStore(i => i.options);
    return (
        <div className="dropdown-main">
            <label className="font-form-question" style={{margin:10}}>
                <span style={{marginBottom:7}}>
                    {item.label}
                    {item.required === '1' ?
                        <sup style={{ color: 'red', fontSize: "14px", marginLeft: '3px' }}>*</sup> : null}
                </span>
                <select className="input-form" style={{ height: "56px", backgroundColor: "#ffffff" }}
                    onChange={(e) => validate(e.target.value)}>
                    {options.map((value) => (
                        <option value={value} key={value}>
                            {value}
                        </option>
                    ))}
                </select>
            </label>
        </div>
    )
}
export default DropdownInputComponent;
