
import React, { useEffect, useState } from "react";
import { useFeedbackStore } from "./useFeedbackStore";
import TextInputComponent from "./TextInputComponent";
import DropdownInputComponent from "./DropdownInputComponent";
import DateInputComponent from "./DateInputComponent";

const UserFormComponent = () => {
    const userFormConfig = useFeedbackStore(i => i.userFormConfig);
    const validationMessages = useFeedbackStore(i => i.validationMessages);
    const profileAvailable = useFeedbackStore(i => i.profileAvailable);
    const setValidateIdentifier =useFeedbackStore(i=>i.setValidateIdentifier)
    const userAvailable = useFeedbackStore(i => i.userAvailable);
    const onChange = useFeedbackStore(i => i.onChange); 
    const userInputs = profileAvailable === true ? null : userAvailable === true ? userFormConfig.filter(field => field.slug !== 'identifier') : userFormConfig;
    useEffect(()=>{
        setValidateIdentifier(userInputs)
    },[userInputs]);
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="user-form" >
                {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <img src={config?.waterMarkImage} className='watermark-img-top' />
                    <img src={config?.logo} />
                </div>
                <span className="font-heading" style={{ display: 'flex', justifyContent: 'center' }}>Contact Info</span> */}
                <form style={{ display: 'flex', flexDirection: 'column' }}>
                    {userInputs && userInputs.map((i, index) => {
                        const validationMessage = validationMessages[i.slug];
                        if (i.type === 'text' || i.type === 'email' || i.type === 'number') {
                            return (
                                <React.Fragment>
                                    <TextInputComponent
                                        item={i}
                                        key={index}
                                        validate={(text) => onChange(text, i.slug)}
                                        validationMessage={validationMessage}
                                    />
                                    <div style={{ color: 'red', fontSize: '13px', paddingLeft: 20 }} className="validation-message">{validationMessage}</div>
                                </React.Fragment>
                            )
                        } else if (i.type === 'dropdown') {
                            return (
                                <React.Fragment>
                                    <DropdownInputComponent
                                        item={i}
                                        key={index}
                                        validate={(text) => onChange(text, i.slug)}
                                    />
                                    <div style={{ color: 'red', fontSize: '13px', paddingLeft: 20 }} className="validation-message">{validationMessage}</div>

                                </React.Fragment>

                            )
                        } else if (i.type === 'date') {
                            return (
                                <React.Fragment>

                                    <DateInputComponent
                                        item={i}
                                        key={index}
                                        validate={(text) => onChange(text, i.slug)}
                                    />
                                    <div style={{ color: 'red', fontSize: '13px', paddingLeft: 20 }} className="validation-message">{validationMessage}</div>
                                </React.Fragment>

                            )
                        }

                    })}
                    {/* <label className="font-question" style={{ color: "#788494", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        <input type="checkbox" className="input-form-checkbox"></input>
                        By agree with our  <span style={{ color: "#C10031" }}> Terms & Conditions.</span>
                    </label> */}
                </form>
            </div>
        </div>
    )
}

export default UserFormComponent;


