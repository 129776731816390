
import React from "react";
import StarRatingComponent from "./StarRatingComponent";
import EmojiRatingComponent from "./EmojiRatingComponent ";
import NPSComponent from "./NPSComponent";
import TextAreaComponent from "./TextAreaComponent";
import SelectComponent from "./SelectComponent";
import MultiSelectComponent from "./MultiSelectComponent";

const SubQuestionComponent = ({ question, selected }) => {
  const from = question.threshold.from;
  const to = question.threshold.to;
  const subQuestionsList = {
    "rating":
      <StarRatingComponent question={question} />,
    "emoticon":
      <EmojiRatingComponent question={question} />,
    "nps":
      <NPSComponent question={question} />,
    "text":
      <TextAreaComponent question={question} />,
    "select":
      <SelectComponent question={question} />,
    "multi-select":
      <MultiSelectComponent question={question} />
  }
  if (selected <= to && selected >= from) {
    return subQuestionsList[question.widget_type] || null;
  }
}

export default SubQuestionComponent;